:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: 0.2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}
.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: env(safe-area-inset-left);
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}
.Toastify__toast {
  --y: 0;
  position: relative;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
  overflow: hidden;
}
.Toastify__toast--stacked {
  position: absolute;
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform 0.3s;
}
.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity 0.1s;
}
.Toastify__toast--stacked[data-collapsed=false] {
  overflow: visible;
}
.Toastify__toast--stacked[data-collapsed=true]:not(:last-child) > * {
  opacity: 0;
}
.Toastify__toast--stacked:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--g) * 1px);
  bottom: 100%;
}
.Toastify__toast--stacked[data-pos=top] {
  top: 0;
}
.Toastify__toast--stacked[data-pos=bot] {
  bottom: 0;
}
.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
  transform-origin: top;
}
.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
  transform-origin: bottom;
}
.Toastify__toast--stacked:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: scaleY(3);
  z-index: -1;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}
.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  z-index: 1;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp[data-hidden=true] {
  opacity: 0;
}
.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }
  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow: hidden;
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: var(
    --custom-highlight-background,
    linear-gradient(
      90deg,
      var(--base-color) 0%,
      var(--highlight-color) 50%,
      var(--base-color) 100%
    )
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}

.sr-only{

    position: absolute;

    width: 1px;

    height: 1px;

    padding: 0;

    margin: -1px;

    overflow: hidden;

    clip: rect(0, 0, 0, 0);

    white-space: nowrap;

    border-width: 0
}

.pointer-events-none{

    pointer-events: none
}

.pointer-events-auto{

    pointer-events: auto
}

.visible{

    visibility: visible
}

.invisible{

    visibility: hidden
}

.static{

    position: static
}

.fixed{

    position: fixed
}

.absolute{

    position: absolute
}

.relative{

    position: relative
}

.sticky{

    position: sticky
}

.inset-0{

    inset: 0px
}

.-top-12{

    top: -3rem
}

.bottom-0{

    bottom: 0px
}

.bottom-2{

    bottom: 0.5rem
}

.left-0{

    left: 0px
}

.left-1{

    left: 0.25rem
}

.left-1\/2{

    left: 50%
}

.left-\[5\%\]{

    left: 5%
}

.right-0{

    right: 0px
}

.right-2{

    right: 0.5rem
}

.right-\[1px\]{

    right: 1px
}

.right-\[22px\]{

    right: 22px
}

.right-\[2px\]{

    right: 2px
}

.top-0{

    top: 0px
}

.top-0\.5{

    top: 0.125rem
}

.top-1{

    top: 0.25rem
}

.top-1\/2{

    top: 50%
}

.top-2{

    top: 0.5rem
}

.-z-40{

    z-index: -40
}

.z-0{

    z-index: 0
}

.z-10{

    z-index: 10
}

.z-50{

    z-index: 50
}

.z-\[-1\]{

    z-index: -1
}

.z-\[1000\]{

    z-index: 1000
}

.m-1{

    margin: 0.25rem
}

.mx-0\.5{

    margin-left: 0.125rem;

    margin-right: 0.125rem
}

.mx-1\.5{

    margin-left: 0.375rem;

    margin-right: 0.375rem
}

.mx-4{

    margin-left: 1rem;

    margin-right: 1rem
}

.mx-auto{

    margin-left: auto;

    margin-right: auto
}

.my-1{

    margin-top: 0.25rem;

    margin-bottom: 0.25rem
}

.my-2{

    margin-top: 0.5rem;

    margin-bottom: 0.5rem
}

.my-3{

    margin-top: 0.75rem;

    margin-bottom: 0.75rem
}

.my-4{

    margin-top: 1rem;

    margin-bottom: 1rem
}

.my-6{

    margin-top: 1.5rem;

    margin-bottom: 1.5rem
}

.\!mt-0{

    margin-top: 0px !important
}

.-mb-2{

    margin-bottom: -0.5rem
}

.-mr-\[3px\]{

    margin-right: -3px
}

.mb-1{

    margin-bottom: 0.25rem
}

.mb-12{

    margin-bottom: 3rem
}

.mb-2{

    margin-bottom: 0.5rem
}

.mb-3{

    margin-bottom: 0.75rem
}

.mb-4{

    margin-bottom: 1rem
}

.mb-6{

    margin-bottom: 1.5rem
}

.mb-8{

    margin-bottom: 2rem
}

.mb-9{

    margin-bottom: 2.25rem
}

.ml-1{

    margin-left: 0.25rem
}

.ml-2{

    margin-left: 0.5rem
}

.ml-4{

    margin-left: 1rem
}

.ml-6{

    margin-left: 1.5rem
}

.ml-8{

    margin-left: 2rem
}

.ml-\[-16px\]{

    margin-left: -16px
}

.ml-\[-4px\]{

    margin-left: -4px
}

.ml-auto{

    margin-left: auto
}

.mr-2{

    margin-right: 0.5rem
}

.mt-1{

    margin-top: 0.25rem
}

.mt-2{

    margin-top: 0.5rem
}

.mt-3{

    margin-top: 0.75rem
}

.mt-4{

    margin-top: 1rem
}

.mt-6{

    margin-top: 1.5rem
}

.mt-8{

    margin-top: 2rem
}

.mt-9{

    margin-top: 2.25rem
}

.mt-\[58px\]{

    margin-top: 58px
}

.box-border{

    box-sizing: border-box
}

.block{

    display: block
}

.inline-block{

    display: inline-block
}

.inline{

    display: inline
}

.flex{

    display: flex
}

.inline-flex{

    display: inline-flex
}

.table{

    display: table
}

.grid{

    display: grid
}

.contents{

    display: contents
}

.hidden{

    display: none
}

.h-0{

    height: 0px
}

.h-1{

    height: 0.25rem
}

.h-10{

    height: 2.5rem
}

.h-11{

    height: 2.75rem
}

.h-12{

    height: 3rem
}

.h-14{

    height: 3.5rem
}

.h-16{

    height: 4rem
}

.h-20{

    height: 5rem
}

.h-24{

    height: 6rem
}

.h-3\.5{

    height: 0.875rem
}

.h-32{

    height: 8rem
}

.h-4{

    height: 1rem
}

.h-5{

    height: 1.25rem
}

.h-6{

    height: 1.5rem
}

.h-7{

    height: 1.75rem
}

.h-8{

    height: 2rem
}

.h-\[100dvh\]{

    height: 100dvh
}

.h-\[108px\]{

    height: 108px
}

.h-\[12px\]{

    height: 12px
}

.h-\[1px\]{

    height: 1px
}

.h-\[22px\]{

    height: 22px
}

.h-\[27px\]{

    height: 27px
}

.h-\[2px\]{

    height: 2px
}

.h-\[34px\]{

    height: 34px
}

.h-\[36px\]{

    height: 36px
}

.h-\[80vh\]{

    height: 80vh
}

.h-\[calc\(100vh-4rem\)\]{

    height: calc(100vh - 4rem)
}

.h-auto{

    height: auto
}

.h-fit{

    height: -moz-fit-content;

    height: fit-content
}

.h-full{

    height: 100%
}

.h-inherit{

    height: inherit
}

.h-screen{

    height: 100vh
}

.max-h-0{

    max-height: 0px
}

.max-h-60{

    max-height: 15rem
}

.max-h-\[100vh\]{

    max-height: 100vh
}

.max-h-\[200px\]{

    max-height: 200px
}

.max-h-\[500px\]{

    max-height: 500px
}

.max-h-\[520px\]{

    max-height: 520px
}

.max-h-\[600px\]{

    max-height: 600px
}

.max-h-\[60vh\]{

    max-height: 60vh
}

.max-h-\[70vh\]{

    max-height: 70vh
}

.max-h-\[calc\(70vh-2\.5rem\)\]{

    max-height: calc(70vh - 2.5rem)
}

.min-h-10{

    min-height: 2.5rem
}

.min-h-7{

    min-height: 1.75rem
}

.min-h-8{

    min-height: 2rem
}

.min-h-\[160px\]{

    min-height: 160px
}

.w-0{

    width: 0px
}

.w-1\/12{

    width: 8.333333%
}

.w-1\/2{

    width: 50%
}

.w-1\/3{

    width: 33.333333%
}

.w-1\/4{

    width: 25%
}

.w-1\/5{

    width: 20%
}

.w-1\/6{

    width: 16.666667%
}

.w-10{

    width: 2.5rem
}

.w-16{

    width: 4rem
}

.w-2\/3{

    width: 66.666667%
}

.w-20{

    width: 5rem
}

.w-3\.5{

    width: 0.875rem
}

.w-3\/4{

    width: 75%
}

.w-3\/5{

    width: 60%
}

.w-32{

    width: 8rem
}

.w-4{

    width: 1rem
}

.w-40{

    width: 10rem
}

.w-48{

    width: 12rem
}

.w-5{

    width: 1.25rem
}

.w-6{

    width: 1.5rem
}

.w-7{

    width: 1.75rem
}

.w-8{

    width: 2rem
}

.w-\[10\%\]{

    width: 10%
}

.w-\[12\.5\%\]{

    width: 12.5%
}

.w-\[340px\]{

    width: 340px
}

.w-\[40\%\]{

    width: 40%
}

.w-\[400px\]{

    width: 400px
}

.w-\[41px\]{

    width: 41px
}

.w-\[50\%\]{

    width: 50%
}

.w-\[500px\]{

    width: 500px
}

.w-\[70\%\]{

    width: 70%
}

.w-\[74px\]{

    width: 74px
}

.w-\[90\%\]{

    width: 90%
}

.w-auto{

    width: auto
}

.w-fit{

    width: -moz-fit-content;

    width: fit-content
}

.w-full{

    width: 100%
}

.w-max{

    width: -moz-max-content;

    width: max-content
}

.w-screen{

    width: 100vw
}

.min-w-0{

    min-width: 0px
}

.min-w-10{

    min-width: 2.5rem
}

.min-w-16{

    min-width: 4rem
}

.min-w-24{

    min-width: 6rem
}

.min-w-64{

    min-width: 16rem
}

.min-w-7{

    min-width: 1.75rem
}

.min-w-8{

    min-width: 2rem
}

.min-w-\[175px\]{

    min-width: 175px
}

.min-w-\[180px\]{

    min-width: 180px
}

.min-w-\[750px\]{

    min-width: 750px
}

.min-w-\[800px\]{

    min-width: 800px
}

.min-w-fit{

    min-width: -moz-fit-content;

    min-width: fit-content
}

.max-w-2xl{

    max-width: 42rem
}

.max-w-3xl{

    max-width: 48rem
}

.max-w-80{

    max-width: 20rem
}

.max-w-\[1200px\]{

    max-width: 1200px
}

.max-w-\[150px\]{

    max-width: 150px
}

.max-w-\[250px\]{

    max-width: 250px
}

.max-w-\[320px\]{

    max-width: 320px
}

.max-w-\[480px\]{

    max-width: 480px
}

.max-w-\[50\%\]{

    max-width: 50%
}

.max-w-\[920px\]{

    max-width: 920px
}

.max-w-full{

    max-width: 100%
}

.max-w-lg{

    max-width: 32rem
}

.max-w-md{

    max-width: 28rem
}

.max-w-sm{

    max-width: 24rem
}

.max-w-xl{

    max-width: 36rem
}

.flex-1{

    flex: 1 1 0%
}

.flex-\[1\]{

    flex: 1
}

.flex-\[3\]{

    flex: 3
}

.flex-shrink-0{

    flex-shrink: 0
}

.shrink-0{

    flex-shrink: 0
}

.flex-grow{

    flex-grow: 1
}

.grow{

    flex-grow: 1
}

.table-auto{

    table-layout: auto
}

.-translate-x-1\/2{

    --tw-translate-x: -50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2{

    --tw-translate-y: -50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-\[27px\]{

    --tw-translate-x: 27px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-\[33px\]{

    --tw-translate-x: 33px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-rotate-180{

    --tw-rotate: -180deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180{

    --tw-rotate: 180deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-125{

    --tw-scale-x: 1.25;

    --tw-scale-y: 1.25;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform{

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes fadeIn{

    0%{

        opacity: 0
    }

    100%{

        opacity: 1
    }
}

.animate-fade-in{

    animation: fadeIn 1s cubic-bezier(0.4, 0, 0.2, 1) forwards
}

@keyframes fadeOut{

    0%{

        opacity: 1
    }

    100%{

        opacity: 0
    }
}

.animate-fade-out{

    animation: fadeOut 1s cubic-bezier(0.4, 0, 0.2, 1) forwards
}

@keyframes spin{

    to{

        transform: rotate(360deg)
    }
}

.animate-spin{

    animation: spin 1s linear infinite
}

.cursor-default{

    cursor: default
}

.cursor-not-allowed{

    cursor: not-allowed
}

.cursor-pointer{

    cursor: pointer
}

.cursor-text{

    cursor: text
}

.select-none{

    -webkit-user-select: none;

       -moz-user-select: none;

            user-select: none
}

.resize-none{

    resize: none
}

.list-outside{

    list-style-position: outside
}

.list-decimal{

    list-style-type: decimal
}

.list-disc{

    list-style-type: disc
}

.grid-cols-1{

    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2{

    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-5{

    grid-template-columns: repeat(5, minmax(0, 1fr))
}

.flex-row{

    flex-direction: row
}

.flex-col{

    flex-direction: column
}

.flex-wrap{

    flex-wrap: wrap
}

.place-content-center{

    place-content: center
}

.content-center{

    align-content: center
}

.items-start{

    align-items: flex-start
}

.items-end{

    align-items: flex-end
}

.items-center{

    align-items: center
}

.items-stretch{

    align-items: stretch
}

.justify-start{

    justify-content: flex-start
}

.justify-end{

    justify-content: flex-end
}

.justify-center{

    justify-content: center
}

.justify-between{

    justify-content: space-between
}

.justify-items-center{

    justify-items: center
}

.gap-0{

    gap: 0px
}

.gap-0\.5{

    gap: 0.125rem
}

.gap-1{

    gap: 0.25rem
}

.gap-1\.5{

    gap: 0.375rem
}

.gap-12{

    gap: 3rem
}

.gap-16{

    gap: 4rem
}

.gap-2{

    gap: 0.5rem
}

.gap-2\.5{

    gap: 0.625rem
}

.gap-3{

    gap: 0.75rem
}

.gap-4{

    gap: 1rem
}

.gap-5{

    gap: 1.25rem
}

.gap-6{

    gap: 1.5rem
}

.gap-8{

    gap: 2rem
}

.gap-\[10px\]{

    gap: 10px
}

.gap-\[23px\]{

    gap: 23px
}

.gap-\[2px\]{

    gap: 2px
}

.gap-\[3px\]{

    gap: 3px
}

.gap-\[6px\]{

    gap: 6px
}

.gap-x-2{

    -moz-column-gap: 0.5rem;

         column-gap: 0.5rem
}

.gap-y-1{

    row-gap: 0.25rem
}

.space-x-1 > :not([hidden]) ~ :not([hidden]){

    --tw-space-x-reverse: 0;

    margin-right: calc(0.25rem * var(--tw-space-x-reverse));

    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){

    --tw-space-x-reverse: 0;

    margin-right: calc(0.5rem * var(--tw-space-x-reverse));

    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){

    --tw-space-x-reverse: 0;

    margin-right: calc(1rem * var(--tw-space-x-reverse));

    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){

    --tw-space-y-reverse: 0;

    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.divide-x > :not([hidden]) ~ :not([hidden]){

    --tw-divide-x-reverse: 0;

    border-right-width: calc(1px * var(--tw-divide-x-reverse));

    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y > :not([hidden]) ~ :not([hidden]){

    --tw-divide-y-reverse: 0;

    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));

    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-dashed > :not([hidden]) ~ :not([hidden]){

    border-style: dashed
}

.divide-primary-active > :not([hidden]) ~ :not([hidden]){

    --tw-divide-opacity: 1;

    border-color: rgb(52 45 137 / var(--tw-divide-opacity))
}

.divide-white\/10 > :not([hidden]) ~ :not([hidden]){

    border-color: rgb(255 255 255 / 0.1)
}

.divide-white\/20 > :not([hidden]) ~ :not([hidden]){

    border-color: rgb(255 255 255 / 0.2)
}

.self-center{

    align-self: center
}

.self-stretch{

    align-self: stretch
}

.justify-self-end{

    justify-self: end
}

.overflow-auto{

    overflow: auto
}

.overflow-hidden{

    overflow: hidden
}

.overflow-x-auto{

    overflow-x: auto
}

.overflow-y-auto{

    overflow-y: auto
}

.overflow-x-hidden{

    overflow-x: hidden
}

.overflow-y-hidden{

    overflow-y: hidden
}

.overflow-y-scroll{

    overflow-y: scroll
}

.text-ellipsis{

    text-overflow: ellipsis
}

.whitespace-normal{

    white-space: normal
}

.whitespace-nowrap{

    white-space: nowrap
}

.whitespace-pre-line{

    white-space: pre-line
}

.whitespace-pre-wrap{

    white-space: pre-wrap
}

.break-words{

    overflow-wrap: break-word
}

.rounded{

    border-radius: 0.25rem
}

.rounded-\[0_3px_3px_3px\]{

    border-radius: 0 3px 3px 3px
}

.rounded-\[0px_8px_8px_8px\]{

    border-radius: 0px 8px 8px 8px
}

.rounded-\[4px\]{

    border-radius: 4px
}

.rounded-\[5px\]{

    border-radius: 5px
}

.rounded-\[9px\]{

    border-radius: 9px
}

.rounded-full{

    border-radius: 9999px
}

.rounded-lg{

    border-radius: 0.5rem
}

.rounded-md{

    border-radius: 0.375rem
}

.rounded-none{

    border-radius: 0px
}

.rounded-sm{

    border-radius: 0.125rem
}

.rounded-xl{

    border-radius: 0.75rem
}

.rounded-b-lg{

    border-bottom-right-radius: 0.5rem;

    border-bottom-left-radius: 0.5rem
}

.rounded-b-none{

    border-bottom-right-radius: 0px;

    border-bottom-left-radius: 0px
}

.rounded-l{

    border-top-left-radius: 0.25rem;

    border-bottom-left-radius: 0.25rem
}

.rounded-l-md{

    border-top-left-radius: 0.375rem;

    border-bottom-left-radius: 0.375rem
}

.rounded-l-none{

    border-top-left-radius: 0px;

    border-bottom-left-radius: 0px
}

.rounded-r-full{

    border-top-right-radius: 9999px;

    border-bottom-right-radius: 9999px
}

.rounded-r-md{

    border-top-right-radius: 0.375rem;

    border-bottom-right-radius: 0.375rem
}

.rounded-r-none{

    border-top-right-radius: 0px;

    border-bottom-right-radius: 0px
}

.rounded-t{

    border-top-left-radius: 0.25rem;

    border-top-right-radius: 0.25rem
}

.rounded-t-\[3px\]{

    border-top-left-radius: 3px;

    border-top-right-radius: 3px
}

.rounded-t-lg{

    border-top-left-radius: 0.5rem;

    border-top-right-radius: 0.5rem
}

.border{

    border-width: 1px
}

.border-0{

    border-width: 0px
}

.border-1{

    border-width: 0.063rem
}

.border-2{

    border-width: 2px
}

.border-\[1\.25px\]{

    border-width: 1.25px
}

.border-b{

    border-bottom-width: 1px
}

.border-b-0{

    border-bottom-width: 0px
}

.border-b-1{

    border-bottom-width: 0.063rem
}

.border-b-2{

    border-bottom-width: 2px
}

.border-b-\[0\.1px\]{

    border-bottom-width: 0.1px
}

.border-b-\[0\.5px\]{

    border-bottom-width: 0.5px
}

.border-l-0{

    border-left-width: 0px
}

.border-l-4{

    border-left-width: 4px
}

.border-r{

    border-right-width: 1px
}

.border-r-0{

    border-right-width: 0px
}

.border-r-1{

    border-right-width: 0.063rem
}

.border-t{

    border-top-width: 1px
}

.border-t-0{

    border-top-width: 0px
}

.border-t-\[0\.1px\]{

    border-top-width: 0.1px
}

.border-solid{

    border-style: solid
}

.border-dashed{

    border-style: dashed
}

.border-none{

    border-style: none
}

.border-\[rgb\(68\2c 68\2c 96\)\]{

    --tw-border-opacity: 1;

    border-color: rgb(68 68 96 / var(--tw-border-opacity))
}

.border-\[rgba\(255\2c 255\2c 255\2c 0\.20\)\]{

    border-color: rgba(255,255,255,0.20)
}

.border-blue-chat-border{

    --tw-border-opacity: 1;

    border-color: rgb(30 33 65 / var(--tw-border-opacity))
}

.border-blue-navy-1\/5{

    border-color: #095A7C58
}

.border-fuchsia-destructive{

    --tw-border-opacity: 1;

    border-color: rgb(255 51 108 / var(--tw-border-opacity))
}

.border-fuchsia-error{

    --tw-border-opacity: 1;

    border-color: rgb(255 11 80 / var(--tw-border-opacity))
}

.border-gray{

    --tw-border-opacity: 1;

    border-color: rgb(68 68 96 / var(--tw-border-opacity))
}

.border-gray-300{

    --tw-border-opacity: 1;

    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.border-gray-dark{

    --tw-border-opacity: 1;

    border-color: rgb(44 46 64 / var(--tw-border-opacity))
}

.border-gray-light{

    border-color: #095A7C37
}

.border-highlight{

    --tw-border-opacity: 1;

    border-color: rgb(84 254 209 / var(--tw-border-opacity))
}

.border-primary-active{

    --tw-border-opacity: 1;

    border-color: rgb(52 45 137 / var(--tw-border-opacity))
}

.border-primary-border{

    border-color: #6761fa5e
}

.border-primary-border-alt{

    border-color: #e6c2ff1a
}

.border-primary-border-light{

    --tw-border-opacity: 1;

    border-color: rgb(37 39 69 / var(--tw-border-opacity))
}

.border-primary-bright{

    --tw-border-opacity: 1;

    border-color: rgb(72 38 253 / var(--tw-border-opacity))
}

.border-primary-dull{

    --tw-border-opacity: 1;

    border-color: rgb(150 148 181 / var(--tw-border-opacity))
}

.border-primary-dull\/20{

    border-color: rgb(150 148 181 / 0.2)
}

.border-primary-highlight{

    --tw-border-opacity: 1;

    border-color: rgb(141 118 255 / var(--tw-border-opacity))
}

.border-red-400{

    --tw-border-opacity: 1;

    border-color: rgb(248 113 113 / var(--tw-border-opacity))
}

.border-red-500{

    --tw-border-opacity: 1;

    border-color: rgb(239 68 68 / var(--tw-border-opacity))
}

.border-transparent{

    border-color: transparent
}

.border-white{

    --tw-border-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-white\/10{

    border-color: rgb(255 255 255 / 0.1)
}

.border-white\/20{

    border-color: rgb(255 255 255 / 0.2)
}

.border-white\/5{

    border-color: rgb(255 255 255 / 0.05)
}

.border-white\/50{

    border-color: rgb(255 255 255 / 0.5)
}

.border-yellow-300{

    --tw-border-opacity: 1;

    border-color: rgb(253 224 71 / var(--tw-border-opacity))
}

.border-yellow-warning{

    --tw-border-opacity: 1;

    border-color: rgb(253 235 144 / var(--tw-border-opacity))
}

.border-x-blue-chat-border{

    --tw-border-opacity: 1;

    border-left-color: rgb(30 33 65 / var(--tw-border-opacity));

    border-right-color: rgb(30 33 65 / var(--tw-border-opacity))
}

.border-b-blue-chat-border{

    --tw-border-opacity: 1;

    border-bottom-color: rgb(30 33 65 / var(--tw-border-opacity))
}

.border-b-primary-border-alt{

    border-bottom-color: #e6c2ff1a
}

.border-t-primary-dull{

    --tw-border-opacity: 1;

    border-top-color: rgb(150 148 181 / var(--tw-border-opacity))
}

.border-opacity-10{

    --tw-border-opacity: 0.1
}

.border-opacity-20{

    --tw-border-opacity: 0.2
}

.border-opacity-30{

    --tw-border-opacity: 0.3
}

.bg-black{

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-black-overlay{

    background-color: rgba(9, 9, 13, 0.50)
}

.bg-blue{

    --tw-bg-opacity: 1;

    background-color: rgb(22 18 61 / var(--tw-bg-opacity))
}

.bg-blue-500{

    --tw-bg-opacity: 1;

    background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}

.bg-blue-chat-background{

    --tw-bg-opacity: 1;

    background-color: rgb(13 15 31 / var(--tw-bg-opacity))
}

.bg-blue-chat-border{

    --tw-bg-opacity: 1;

    background-color: rgb(30 33 65 / var(--tw-bg-opacity))
}

.bg-blue-navy{

    --tw-bg-opacity: 1;

    background-color: rgb(9 90 124 / var(--tw-bg-opacity))
}

.bg-fuchsia-error{

    --tw-bg-opacity: 1;

    background-color: rgb(255 11 80 / var(--tw-bg-opacity))
}

.bg-gray{

    --tw-bg-opacity: 1;

    background-color: rgb(68 68 96 / var(--tw-bg-opacity))
}

.bg-gray-400{

    --tw-bg-opacity: 1;

    background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}

.bg-gray-dark{

    --tw-bg-opacity: 1;

    background-color: rgb(44 46 64 / var(--tw-bg-opacity))
}

.bg-highlight{

    --tw-bg-opacity: 1;

    background-color: rgb(84 254 209 / var(--tw-bg-opacity))
}

.bg-highlight\/20{

    background-color: rgb(84 254 209 / 0.2)
}

.bg-off-black{

    --tw-bg-opacity: 1;

    background-color: rgb(9 9 9 / var(--tw-bg-opacity))
}

.bg-paper{

    --tw-bg-opacity: 1;

    background-color: rgb(253 252 251 / var(--tw-bg-opacity))
}

.bg-primary-active{

    --tw-bg-opacity: 1;

    background-color: rgb(52 45 137 / var(--tw-bg-opacity))
}

.bg-primary-border-alt{

    background-color: #e6c2ff1a
}

.bg-primary-bright{

    --tw-bg-opacity: 1;

    background-color: rgb(72 38 253 / var(--tw-bg-opacity))
}

.bg-primary-dull{

    --tw-bg-opacity: 1;

    background-color: rgb(150 148 181 / var(--tw-bg-opacity))
}

.bg-primary-dull\/10{

    background-color: rgb(150 148 181 / 0.1)
}

.bg-primary-highlight{

    --tw-bg-opacity: 1;

    background-color: rgb(141 118 255 / var(--tw-bg-opacity))
}

.bg-primary-layer-3{

    --tw-bg-opacity: 1;

    background-color: rgb(29 30 54 / var(--tw-bg-opacity))
}

.bg-primary-markdown-table-data{

    --tw-bg-opacity: 1;

    background-color: rgb(13 14 25 / var(--tw-bg-opacity))
}

.bg-primary-modal-bg{

    --tw-bg-opacity: 1;

    background-color: rgb(21 23 46 / var(--tw-bg-opacity))
}

.bg-primary-model-menu{

    background-color: #06061DA3
}

.bg-primary-panel{

    background-color: #25224575
}

.bg-red-200{

    --tw-bg-opacity: 1;

    background-color: rgb(254 202 202 / var(--tw-bg-opacity))
}

.bg-transparent{

    background-color: transparent
}

.bg-white{

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-white\/10{

    background-color: rgb(255 255 255 / 0.1)
}

.bg-white\/20{

    background-color: rgb(255 255 255 / 0.2)
}

.bg-white\/30{

    background-color: rgb(255 255 255 / 0.3)
}

.bg-yellow-400{

    --tw-bg-opacity: 1;

    background-color: rgb(250 204 21 / var(--tw-bg-opacity))
}

.bg-yellow-warning{

    --tw-bg-opacity: 1;

    background-color: rgb(253 235 144 / var(--tw-bg-opacity))
}

.bg-opacity-0{

    --tw-bg-opacity: 0
}

.bg-opacity-10{

    --tw-bg-opacity: 0.1
}

.bg-opacity-100{

    --tw-bg-opacity: 1
}

.bg-opacity-30{

    --tw-bg-opacity: 0.3
}

.bg-opacity-60{

    --tw-bg-opacity: 0.6
}

.bg-gradient-to-r{

    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.bg-gradient-to-t{

    background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.from-off-black{

    --tw-gradient-from: #090909 var(--tw-gradient-from-position);

    --tw-gradient-to: rgb(9 9 9 / 0) var(--tw-gradient-to-position);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-primary-bright{

    --tw-gradient-from: #4826FD var(--tw-gradient-from-position);

    --tw-gradient-to: rgb(72 38 253 / 0) var(--tw-gradient-to-position);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-primary-dull{

    --tw-gradient-from: #9694B5 var(--tw-gradient-from-position);

    --tw-gradient-to: rgb(150 148 181 / 0) var(--tw-gradient-to-position);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-solid-gradient-start{

    --tw-gradient-from: #320DC4 var(--tw-gradient-from-position);

    --tw-gradient-to: rgb(50 13 196 / 0) var(--tw-gradient-to-position);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-\[rgba\(39\2c 40\2c 62\2c 0\)\]{

    --tw-gradient-to: rgba(39,40,62,0) var(--tw-gradient-to-position)
}

.to-primary-highlight{

    --tw-gradient-to: #8D76FF var(--tw-gradient-to-position)
}

.to-solid-gradient-end{

    --tw-gradient-to: #8544D9 var(--tw-gradient-to-position)
}

.to-transparent{

    --tw-gradient-to: transparent var(--tw-gradient-to-position)
}

.bg-contain{

    background-size: contain
}

.bg-no-repeat{

    background-repeat: no-repeat
}

.p-0{

    padding: 0px
}

.p-0\.5{

    padding: 0.125rem
}

.p-1{

    padding: 0.25rem
}

.p-1\.5{

    padding: 0.375rem
}

.p-10{

    padding: 2.5rem
}

.p-12{

    padding: 3rem
}

.p-2{

    padding: 0.5rem
}

.p-3{

    padding: 0.75rem
}

.p-4{

    padding: 1rem
}

.p-6{

    padding: 1.5rem
}

.p-7{

    padding: 1.75rem
}

.p-8{

    padding: 2rem
}

.p-\[1px\]{

    padding: 1px
}

.p-\[30px\]{

    padding: 30px
}

.p-\[3px\]{

    padding: 3px
}

.p-\[9px\]{

    padding: 9px
}

.px-0\.5{

    padding-left: 0.125rem;

    padding-right: 0.125rem
}

.px-1{

    padding-left: 0.25rem;

    padding-right: 0.25rem
}

.px-10{

    padding-left: 2.5rem;

    padding-right: 2.5rem
}

.px-12{

    padding-left: 3rem;

    padding-right: 3rem
}

.px-2{

    padding-left: 0.5rem;

    padding-right: 0.5rem
}

.px-2\.5{

    padding-left: 0.625rem;

    padding-right: 0.625rem
}

.px-24{

    padding-left: 6rem;

    padding-right: 6rem
}

.px-3{

    padding-left: 0.75rem;

    padding-right: 0.75rem
}

.px-4{

    padding-left: 1rem;

    padding-right: 1rem
}

.px-5{

    padding-left: 1.25rem;

    padding-right: 1.25rem
}

.px-6{

    padding-left: 1.5rem;

    padding-right: 1.5rem
}

.px-\[10px\]{

    padding-left: 10px;

    padding-right: 10px
}

.px-\[13px\]{

    padding-left: 13px;

    padding-right: 13px
}

.px-\[14px\]{

    padding-left: 14px;

    padding-right: 14px
}

.px-\[15px\]{

    padding-left: 15px;

    padding-right: 15px
}

.px-\[20px\]{

    padding-left: 20px;

    padding-right: 20px
}

.px-\[2px\]{

    padding-left: 2px;

    padding-right: 2px
}

.px-\[8px\]{

    padding-left: 8px;

    padding-right: 8px
}

.py-0{

    padding-top: 0px;

    padding-bottom: 0px
}

.py-0\.5{

    padding-top: 0.125rem;

    padding-bottom: 0.125rem
}

.py-1{

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}

.py-10{

    padding-top: 2.5rem;

    padding-bottom: 2.5rem
}

.py-2{

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}

.py-3{

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}

.py-4{

    padding-top: 1rem;

    padding-bottom: 1rem
}

.py-5{

    padding-top: 1.25rem;

    padding-bottom: 1.25rem
}

.py-6{

    padding-top: 1.5rem;

    padding-bottom: 1.5rem
}

.py-9{

    padding-top: 2.25rem;

    padding-bottom: 2.25rem
}

.py-\[10px\]{

    padding-top: 10px;

    padding-bottom: 10px
}

.py-\[18px\]{

    padding-top: 18px;

    padding-bottom: 18px
}

.py-\[1px\]{

    padding-top: 1px;

    padding-bottom: 1px
}

.pb-1{

    padding-bottom: 0.25rem
}

.pb-2{

    padding-bottom: 0.5rem
}

.pb-24{

    padding-bottom: 6rem
}

.pb-3{

    padding-bottom: 0.75rem
}

.pb-4{

    padding-bottom: 1rem
}

.pb-5{

    padding-bottom: 1.25rem
}

.pb-6{

    padding-bottom: 1.5rem
}

.pb-8{

    padding-bottom: 2rem
}

.pb-\[10px\]{

    padding-bottom: 10px
}

.pb-\[6px\]{

    padding-bottom: 6px
}

.pl-0{

    padding-left: 0px
}

.pl-2{

    padding-left: 0.5rem
}

.pl-3{

    padding-left: 0.75rem
}

.pl-4{

    padding-left: 1rem
}

.pl-5{

    padding-left: 1.25rem
}

.pl-6{

    padding-left: 1.5rem
}

.pl-8{

    padding-left: 2rem
}

.pl-\[10px\]{

    padding-left: 10px
}

.pl-\[13px\]{

    padding-left: 13px
}

.pl-\[15px\]{

    padding-left: 15px
}

.pl-\[20px\]{

    padding-left: 20px
}

.pl-\[8px\]{

    padding-left: 8px
}

.pr-1{

    padding-right: 0.25rem
}

.pr-16{

    padding-right: 4rem
}

.pr-2{

    padding-right: 0.5rem
}

.pr-3{

    padding-right: 0.75rem
}

.pr-4{

    padding-right: 1rem
}

.pr-\[10px\]{

    padding-right: 10px
}

.pr-\[13px\]{

    padding-right: 13px
}

.pr-\[15px\]{

    padding-right: 15px
}

.pr-\[20px\]{

    padding-right: 20px
}

.pr-\[8px\]{

    padding-right: 8px
}

.pt-0\.5{

    padding-top: 0.125rem
}

.pt-1{

    padding-top: 0.25rem
}

.pt-1\.5{

    padding-top: 0.375rem
}

.pt-10{

    padding-top: 2.5rem
}

.pt-12{

    padding-top: 3rem
}

.pt-2{

    padding-top: 0.5rem
}

.pt-3{

    padding-top: 0.75rem
}

.pt-4{

    padding-top: 1rem
}

.pt-5{

    padding-top: 1.25rem
}

.pt-6{

    padding-top: 1.5rem
}

.pt-\[6px\]{

    padding-top: 6px
}

.text-left{

    text-align: left
}

.text-center{

    text-align: center
}

.text-right{

    text-align: right
}

.text-justify{

    text-align: justify
}

.align-top{

    vertical-align: top
}

.align-middle{

    vertical-align: middle
}

.font-ibmPlexMono{

    font-family: var(--font-ibmPlexMono)
}

.font-ibmPlexSans{

    font-family: var(--font-ibmPlexSans)
}

.font-oxanium{

    font-family: var(--font-oxanium)
}

.text-2xl{

    font-size: 1.5rem;

    line-height: 2rem
}

.text-3xl{

    font-size: 1.875rem;

    line-height: 2.25rem
}

.text-4xl{

    font-size: 2.25rem;

    line-height: 2.5rem
}

.text-\[10px\]{

    font-size: 10px
}

.text-base{

    font-size: 1rem;

    line-height: 1.5rem
}

.text-lg{

    font-size: 1.125rem;

    line-height: 1.75rem
}

.text-sm{

    font-size: 0.875rem;

    line-height: 1.25rem
}

.text-xl{

    font-size: 1.25rem;

    line-height: 1.75rem
}

.text-xs{

    font-size: 0.75rem;

    line-height: 1rem
}

.font-bold{

    font-weight: 700
}

.font-light{

    font-weight: 300
}

.font-medium{

    font-weight: 468
}

.font-normal{

    font-weight: 400
}

.font-semibold{

    font-weight: 600
}

.font-semilight{

    font-weight: 350
}

.font-seminormal{

    font-weight: 375
}

.uppercase{

    text-transform: uppercase
}

.lowercase{

    text-transform: lowercase
}

.capitalize{

    text-transform: capitalize
}

.italic{

    font-style: italic
}

.tracking-normal{

    letter-spacing: 0em
}

.tracking-tight{

    letter-spacing: -0.025em
}

.tracking-tighter{

    letter-spacing: -0.05em
}

.tracking-wide{

    letter-spacing: 0.025em
}

.text-black-raisin{

    --tw-text-opacity: 1;

    color: rgb(22 25 37 / var(--tw-text-opacity))
}

.text-blue-navy{

    --tw-text-opacity: 1;

    color: rgb(9 90 124 / var(--tw-text-opacity))
}

.text-fuchsia-destructive{

    --tw-text-opacity: 1;

    color: rgb(255 51 108 / var(--tw-text-opacity))
}

.text-fuchsia-error{

    --tw-text-opacity: 1;

    color: rgb(255 11 80 / var(--tw-text-opacity))
}

.text-gray{

    --tw-text-opacity: 1;

    color: rgb(68 68 96 / var(--tw-text-opacity))
}

.text-gray-200{

    --tw-text-opacity: 1;

    color: rgb(229 231 235 / var(--tw-text-opacity))
}

.text-highlight{

    --tw-text-opacity: 1;

    color: rgb(84 254 209 / var(--tw-text-opacity))
}

.text-markdown-baby-blue{

    --tw-text-opacity: 1;

    color: rgb(146 193 251 / var(--tw-text-opacity))
}

.text-markdown-periwinkle{

    --tw-text-opacity: 1;

    color: rgb(120 116 254 / var(--tw-text-opacity))
}

.text-primary-dark{

    --tw-text-opacity: 1;

    color: rgb(37 34 69 / var(--tw-text-opacity))
}

.text-primary-dull{

    --tw-text-opacity: 1;

    color: rgb(150 148 181 / var(--tw-text-opacity))
}

.text-primary-highlight{

    --tw-text-opacity: 1;

    color: rgb(141 118 255 / var(--tw-text-opacity))
}

.text-white{

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-white\/30{

    color: rgb(255 255 255 / 0.3)
}

.text-white\/50{

    color: rgb(255 255 255 / 0.5)
}

.text-yellow-warning{

    --tw-text-opacity: 1;

    color: rgb(253 235 144 / var(--tw-text-opacity))
}

.underline{

    text-decoration-line: underline
}

.decoration-gray-400{

    text-decoration-color: #9ca3af
}

.opacity-0{

    opacity: 0
}

.opacity-100{

    opacity: 1
}

.opacity-50{

    opacity: 0.5
}

.opacity-70{

    opacity: 0.7
}

.opacity-75{

    opacity: 0.75
}

.opacity-80{

    opacity: 0.8
}

.shadow-\[0px_13px_26\.5px_0px_rgba\(0\2c 0\2c 0\2c 0\.54\)\]{

    --tw-shadow: 0px 13px 26.5px 0px rgba(0,0,0,0.54);

    --tw-shadow-colored: 0px 13px 26.5px 0px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-\[inset_0px_0px_40px_0px_rgba\(32\2c 0\2c 200\2c 0\.30\)\]{

    --tw-shadow: inset 0px 0px 40px 0px rgba(32,0,200,0.30);

    --tw-shadow-colored: inset 0px 0px 40px 0px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg{

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md{

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-prompt-bar-focus{

    --tw-shadow: 0 0 30px rgba(50, 3, 200, 1);

    --tw-shadow-colored: 0 0 30px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-segment-selected{

    --tw-shadow: 0 0 20px rgba(60, 0, 255, 1);

    --tw-shadow-colored: 0 0 20px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-toggle-active{

    --tw-shadow: 0px 0px 20px 4px rgb(84 254 209);

    --tw-shadow-colored: 0px 0px 20px 4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-toggle-disabled{

    --tw-shadow: 0px 0px 20px 4px rgb(84 254 209 / 0.2);

    --tw-shadow-colored: 0px 0px 20px 4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl{

    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none{

    outline: 2px solid transparent;

    outline-offset: 2px
}

.blur{

    --tw-blur: blur(8px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-sm{

    --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.invert{

    --tw-invert: invert(100%);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter{

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur-\[53\.5px\]{

    --tw-backdrop-blur: blur(53.5px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-\[5px\]{

    --tw-backdrop-blur: blur(5px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-sm{

    --tw-backdrop-blur: blur(4px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.transition{

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-\[border\]{

    transition-property: border;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-all{

    transition-property: all;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-colors{

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-none{

    transition-property: none
}

.transition-opacity{

    transition-property: opacity;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-transform{

    transition-property: transform;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.delay-100{

    transition-delay: 100ms
}

.duration-100{

    transition-duration: 100ms
}

.duration-200{

    transition-duration: 200ms
}

.duration-300{

    transition-duration: 300ms
}

.duration-500{

    transition-duration: 500ms
}

.duration-\[350ms\]{

    transition-duration: 350ms
}

.duration-toggle-shadow{

    transition-duration: 400ms
}

.duration-toggle-transform{

    transition-duration: 300ms
}

.ease-in-out{

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.ease-out{

    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.\[border-image\:repeating-linear-gradient\(to_right\2c rgb\(68\2c 68\2c 96\)_0\2c rgb\(68\2c 68\2c 96\)_8px\2c transparent_8px\2c transparent_16px\)_1\]{

    -o-border-image: repeating-linear-gradient(to right,rgb(68,68,96) 0,rgb(68,68,96) 8px,transparent 8px,transparent 16px) 1;

       border-image: repeating-linear-gradient(to right,rgb(68,68,96) 0,rgb(68,68,96) 8px,transparent 8px,transparent 16px) 1
}

.\[transform\:rotate\(0deg\)\]{

    transform: rotate(0deg)
}

.\[transform\:rotate\(360deg\)\]{

    transform: rotate(360deg)
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600&display=swap');

.before\:absolute::before{

    content: var(--tw-content);

    position: absolute
}

.before\:-inset-\[1px\]::before{

    content: var(--tw-content);

    inset: -1px
}

.before\:left-0::before{

    content: var(--tw-content);

    left: 0px
}

.before\:top-2::before{

    content: var(--tw-content);

    top: 0.5rem
}

.before\:-z-10::before{

    content: var(--tw-content);

    z-index: -10
}

.before\:h-1::before{

    content: var(--tw-content);

    height: 0.25rem
}

.before\:w-1::before{

    content: var(--tw-content);

    width: 0.25rem
}

.before\:rounded-\[9px\]::before{

    content: var(--tw-content);

    border-radius: 9px
}

.before\:rounded-full::before{

    content: var(--tw-content);

    border-radius: 9999px
}

.before\:bg-current::before{

    content: var(--tw-content);

    background-color: currentColor
}

.before\:bg-diagonal-corners::before{

    content: var(--tw-content);

    background-image: linear-gradient(-45deg, var(--tw-gradient-from) 0%, black 25%, black 75%, var(--tw-gradient-from) 100%)
}

.before\:from-primary-glow::before{

    content: var(--tw-content);

    --tw-gradient-from: #664DEF var(--tw-gradient-from-position);

    --tw-gradient-to: rgb(102 77 239 / 0) var(--tw-gradient-to-position);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.before\:opacity-0::before{

    content: var(--tw-content);

    opacity: 0
}

.before\:opacity-100::before{

    content: var(--tw-content);

    opacity: 1
}

.before\:transition-opacity::before{

    content: var(--tw-content);

    transition-property: opacity;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.before\:duration-300::before{

    content: var(--tw-content);

    transition-duration: 300ms
}

.before\:content-\[\"\"\]::before{

    --tw-content: "";

    content: var(--tw-content)
}

.after\:flex::after{

    content: var(--tw-content);

    display: flex
}

.after\:items-center::after{

    content: var(--tw-content);

    align-items: center
}

.after\:justify-center::after{

    content: var(--tw-content);

    justify-content: center
}

.last\:border-r-0:last-child{

    border-right-width: 0px
}

.focus-within\:border-primary-border-alt:focus-within{

    border-color: #e6c2ff1a
}

.focus-within\:border-primary-highlight:focus-within{

    --tw-border-opacity: 1;

    border-color: rgb(141 118 255 / var(--tw-border-opacity))
}

.hover\:cursor-pointer:hover{

    cursor: pointer
}

.hover\:border-0:hover{

    border-width: 0px
}

.hover\:border-blue-navy:hover{

    --tw-border-opacity: 1;

    border-color: rgb(9 90 124 / var(--tw-border-opacity))
}

.hover\:border-primary-active:hover{

    --tw-border-opacity: 1;

    border-color: rgb(52 45 137 / var(--tw-border-opacity))
}

.hover\:border-primary-bright-hover:hover{

    --tw-border-opacity: 1;

    border-color: rgb(53 17 245 / var(--tw-border-opacity))
}

.hover\:border-primary-highlight:hover{

    --tw-border-opacity: 1;

    border-color: rgb(141 118 255 / var(--tw-border-opacity))
}

.hover\:border-primary-panel:hover{

    border-color: #25224575
}

.hover\:border-red-500:hover{

    --tw-border-opacity: 1;

    border-color: rgb(239 68 68 / var(--tw-border-opacity))
}

.hover\:border-white:hover{

    --tw-border-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.hover\:border-white\/10:hover{

    border-color: rgb(255 255 255 / 0.1)
}

.hover\:border-white\/50:hover{

    border-color: rgb(255 255 255 / 0.5)
}

.hover\:border-opacity-70:hover{

    --tw-border-opacity: 0.7
}

.hover\:bg-primary-bright-hover:hover{

    --tw-bg-opacity: 1;

    background-color: rgb(53 17 245 / var(--tw-bg-opacity))
}

.hover\:bg-primary-dull\/20:hover{

    background-color: rgb(150 148 181 / 0.2)
}

.hover\:bg-primary-modal-bg:hover{

    --tw-bg-opacity: 1;

    background-color: rgb(21 23 46 / var(--tw-bg-opacity))
}

.hover\:bg-red-100:hover{

    --tw-bg-opacity: 1;

    background-color: rgb(254 226 226 / var(--tw-bg-opacity))
}

.hover\:bg-transparent:hover{

    background-color: transparent
}

.hover\:bg-white:hover{

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.hover\:bg-white\/10:hover{

    background-color: rgb(255 255 255 / 0.1)
}

.hover\:bg-white\/20:hover{

    background-color: rgb(255 255 255 / 0.2)
}

.hover\:bg-white\/40:hover{

    background-color: rgb(255 255 255 / 0.4)
}

.hover\:bg-white\/5:hover{

    background-color: rgb(255 255 255 / 0.05)
}

.hover\:bg-opacity-10:hover{

    --tw-bg-opacity: 0.1
}

.hover\:py-1:hover{

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}

.hover\:py-9:hover{

    padding-top: 2.25rem;

    padding-bottom: 2.25rem
}

.hover\:text-highlight:hover{

    --tw-text-opacity: 1;

    color: rgb(84 254 209 / var(--tw-text-opacity))
}

.hover\:opacity-50:hover{

    opacity: 0.5
}

.hover\:opacity-80:hover{

    opacity: 0.8
}

.hover\:opacity-85:hover{

    opacity: 0.85
}

.hover\:shadow-\[inset_0px_0px_40px_0px_\#2000C8\]:hover{

    --tw-shadow: inset 0px 0px 40px 0px #2000C8;

    --tw-shadow-colored: inset 0px 0px 40px 0px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-prompt-bar-hover:hover{

    --tw-shadow: 0 0 20px rgba(50, 3, 200, 1);

    --tw-shadow-colored: 0 0 20px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:before\:opacity-100:hover::before{

    content: var(--tw-content);

    opacity: 1
}

.focus-within\:hover\:border-primary-border-alt:hover:focus-within{

    border-color: #e6c2ff1a
}

.focus-within\:hover\:border-primary-highlight:hover:focus-within{

    --tw-border-opacity: 1;

    border-color: rgb(141 118 255 / var(--tw-border-opacity))
}

.focus\:border-blue-navy:focus{

    --tw-border-opacity: 1;

    border-color: rgb(9 90 124 / var(--tw-border-opacity))
}

.focus\:border-red-500:focus{

    --tw-border-opacity: 1;

    border-color: rgb(239 68 68 / var(--tw-border-opacity))
}

.focus\:outline-none:focus{

    outline: 2px solid transparent;

    outline-offset: 2px
}

.enabled\:hover\:border-primary-bright-hover:hover:enabled{

    --tw-border-opacity: 1;

    border-color: rgb(53 17 245 / var(--tw-border-opacity))
}

.enabled\:hover\:border-white\/20:hover:enabled{

    border-color: rgb(255 255 255 / 0.2)
}

.enabled\:hover\:border-white\/50:hover:enabled{

    border-color: rgb(255 255 255 / 0.5)
}

.enabled\:hover\:bg-primary-bright-hover:hover:enabled{

    --tw-bg-opacity: 1;

    background-color: rgb(53 17 245 / var(--tw-bg-opacity))
}

.enabled\:hover\:bg-white\/20:hover:enabled{

    background-color: rgb(255 255 255 / 0.2)
}

.enabled\:hover\:opacity-100:hover:enabled{

    opacity: 1
}

.enabled\:hover\:opacity-50:hover:enabled{

    opacity: 0.5
}

.enabled\:hover\:opacity-90:hover:enabled{

    opacity: 0.9
}

.enabled\:hover\:shadow-\[0px_-1px_46\.1px_0px_\#3C00FF\]:hover:enabled{

    --tw-shadow: 0px -1px 46.1px 0px #3C00FF;

    --tw-shadow-colored: 0px -1px 46.1px 0px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.enabled\:hover\:shadow-\[0px_4px_45px_0px_\#3C00FF\]:hover:enabled{

    --tw-shadow: 0px 4px 45px 0px #3C00FF;

    --tw-shadow-colored: 0px 4px 45px 0px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.enabled\:hover\:shadow-\[0px_4px_45px_0px_\#5900DE\]:hover:enabled{

    --tw-shadow: 0px 4px 45px 0px #5900DE;

    --tw-shadow-colored: 0px 4px 45px 0px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.disabled\:cursor-not-allowed:disabled{

    cursor: not-allowed
}

.disabled\:border-dashed:disabled{

    border-style: dashed
}

.disabled\:border-none:disabled{

    border-style: none
}

.disabled\:border-white\/20:disabled{

    border-color: rgb(255 255 255 / 0.2)
}

.disabled\:bg-white\/10:disabled{

    background-color: rgb(255 255 255 / 0.1)
}

.group\/message:hover .group-hover\/message\:visible{

    visibility: visible
}

.group:hover .group-hover\:opacity-100{

    opacity: 1
}

@media (min-width: 640px){

    .sm\:right-4{

        right: 1rem
    }

    .sm\:top-4{

        top: 1rem
    }

    .sm\:mb-14{

        margin-bottom: 3.5rem
    }

    .sm\:h-full{

        height: 100%
    }

    .sm\:w-auto{

        width: auto
    }

    .sm\:w-full{

        width: 100%
    }

    .sm\:flex-row{

        flex-direction: row
    }

    .sm\:flex-col{

        flex-direction: column
    }

    .sm\:justify-center{

        justify-content: center
    }

    .sm\:gap-1{

        gap: 0.25rem
    }

    .sm\:gap-2{

        gap: 0.5rem
    }

    .sm\:gap-4{

        gap: 1rem
    }

    .sm\:px-20{

        padding-left: 5rem;

        padding-right: 5rem
    }

    .sm\:px-4{

        padding-left: 1rem;

        padding-right: 1rem
    }

    .sm\:px-6{

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }

    .sm\:pl-0{

        padding-left: 0px
    }

    .sm\:pr-4{

        padding-right: 1rem
    }
}

@media (min-width: 768px){

    .md\:h-\[502px\]{

        height: 502px
    }

    .md\:h-full{

        height: 100%
    }

    .md\:w-2\/3{

        width: 66.666667%
    }

    .md\:w-\[440px\]{

        width: 440px
    }

    .md\:w-\[672px\]{

        width: 672px
    }

    .md\:grid-cols-2{

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:flex-row{

        flex-direction: row
    }

    .md\:gap-2{

        gap: 0.5rem
    }

    .md\:p-0{

        padding: 0px
    }

    .md\:p-3{

        padding: 0.75rem
    }
}

@media (min-width: 1024px){

    .lg\:mb-0{

        margin-bottom: 0px
    }

    .lg\:flex{

        display: flex
    }

    .lg\:w-2\/5{

        width: 40%
    }

    .lg\:w-3\/5{

        width: 60%
    }

    .lg\:flex-row{

        flex-direction: row
    }

    .lg\:items-end{

        align-items: flex-end
    }

    .lg\:justify-between{

        justify-content: space-between
    }
}

@media (prefers-color-scheme: dark){

    .dark\:text-gray-600{

        --tw-text-opacity: 1;

        color: rgb(75 85 99 / var(--tw-text-opacity))
    }
}

.\[\&\.Mui-checked\]\:\!text-highlight.Mui-checked{

    --tw-text-opacity: 1 !important;

    color: rgb(84 254 209 / var(--tw-text-opacity)) !important
}

.\[\&\.MuiCheckbox-root\]\:\!text-highlight.MuiCheckbox-root{

    --tw-text-opacity: 1 !important;

    color: rgb(84 254 209 / var(--tw-text-opacity)) !important
}

.\[\&\:hover_svg\]\:text-current:hover svg{

    color: currentColor
}

.\[\&\:hover_svg\]\:text-highlight:hover svg{

    --tw-text-opacity: 1;

    color: rgb(84 254 209 / var(--tw-text-opacity))
}

.\[\&\:hover_svg\]\:text-primary-highlight:hover svg{

    --tw-text-opacity: 1;

    color: rgb(141 118 255 / var(--tw-text-opacity))
}

.\[\&\>\*\:not\(\:last-child\)\]\:border-r-1>*:not(:last-child){

    border-right-width: 0.063rem
}

.\[\&\>\*\:not\(\:last-child\)\]\:border-r-primary-border>*:not(:last-child){

    border-right-color: #6761fa5e
}

.\[\&\>\*\]\:pointer-events-auto>*{

    pointer-events: auto
}

.hover\:\[\&\>\*\]\:text-white>*:hover{

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.\[\&\>a\>span\]\:block>a>span{

    display: block
}

.\[\&\>a\>span\]\:truncate>a>span{

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap
}

.\[\&\>a\>span\]\:transition-\[opacity\2c width\2c color\]>a>span{

    transition-property: opacity,width,color;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.\[\&\>a\>span\]\:duration-300>a>span{

    transition-duration: 300ms
}

.hover\:\[\&\>a\>span\]\:text-white>a>span:hover{

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:\[\&\>a\>span\]\:opacity-100>a>span:hover{

    opacity: 1
}

.\[\&\>a\]\:hover\:\!text-black-raisin:hover>a{

    --tw-text-opacity: 1 !important;

    color: rgb(22 25 37 / var(--tw-text-opacity)) !important
}

.\[\&\>a\]\:hover\:text-black-raisin:hover>a{

    --tw-text-opacity: 1;

    color: rgb(22 25 37 / var(--tw-text-opacity))
}

.\[\&\>button\:enabled\:hover\]\:opacity-100>button:enabled:hover{

    opacity: 1
}

.\[\&\>button\]\:-scale-x-100>button{

    --tw-scale-x: -1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.\[\&\>button\]\:scale-x-100>button{

    --tw-scale-x: 1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.\[\&\>button\]\:transition-transform>button{

    transition-property: transform;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.\[\&\>button\]\:duration-100>button{

    transition-duration: 100ms
}

.\[\&\>div\>span\]\:hover\:text-highlight:hover>div>span{

    --tw-text-opacity: 1;

    color: rgb(84 254 209 / var(--tw-text-opacity))
}

.\[\&\>p\]\:mr-4>p{

    margin-right: 1rem
}

.\[\&\>p\]\:flex-grow>p{

    flex-grow: 1
}

.\[\&\>span\]\:text-white>span{

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.\[\&\>svg\]\:h-2>svg{

    height: 0.5rem
}

.\[\&\>svg\]\:h-3>svg{

    height: 0.75rem
}

.\[\&\>svg\]\:h-4>svg{

    height: 1rem
}

.\[\&\>svg\]\:h-5>svg{

    height: 1.25rem
}

.\[\&\>svg\]\:h-6>svg{

    height: 1.5rem
}

.\[\&\>svg\]\:w-2>svg{

    width: 0.5rem
}

.\[\&\>svg\]\:w-3>svg{

    width: 0.75rem
}

.\[\&\>svg\]\:w-4>svg{

    width: 1rem
}

.\[\&\>svg\]\:w-5>svg{

    width: 1.25rem
}

.\[\&\>svg\]\:w-6>svg{

    width: 1.5rem
}

.\[\&\[aria-expanded\=\"true\"\]\]\:bg-transparent[aria-expanded="true"]{

    background-color: transparent
}

.\[\&_\*\]\:cursor-not-allowed *{

    cursor: not-allowed
}

tr:hover .\[tr\:hover_\&\]\:border-highlight{

    --tw-border-opacity: 1;

    border-color: rgb(84 254 209 / var(--tw-border-opacity))
}

tr:last-child>.\[tr\:last-child\>\&\]\:border-b-0{

    border-bottom-width: 0px
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4acd96e66b7a15d8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c00fdcaa534cf51c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9b64de7575b01f6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e05a5a1d4048d7df-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9387c470a117487-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5b5f10d1030076fd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/66b34bbb56a523d8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/62e8d71f5f62d0a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4f5bd398699bdcf0-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5873ad5359ff35c9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a08baca5e17c57be-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b8347de82b1bd9a8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/99163ae90df46b27-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b9432a067aca8720-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3f479ae50d16e35-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ebec2867f40f78ec-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d607327a37a507c7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2e1b830192b7974a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3aa27b2eb5f698f7-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3478b6abef19b3b3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b6f2eee8808a2bb4-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc2003170c651d45-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d43ef4503e5571d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3281a323710833ec-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/684e5662d94c69e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97b12f7b815cdf76-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/19fc70611c7ee6d5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/01721b474504e7d6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3ccf24bed29cbb82-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_0bccfc';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/be2416cbb012c256-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_0bccfc';src: local("Arial");ascent-override: 76.16%;descent-override: 20.43%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_0bccfc {font-family: '__IBM_Plex_Mono_0bccfc', '__IBM_Plex_Mono_Fallback_0bccfc'
}.__variable_0bccfc {--font-ibmPlexMono: '__IBM_Plex_Mono_0bccfc', '__IBM_Plex_Mono_Fallback_0bccfc'
}

/* latin-ext */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/920470fac8c68c8c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/946a1ee6daf04c65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/920470fac8c68c8c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/946a1ee6daf04c65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/920470fac8c68c8c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/946a1ee6daf04c65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/920470fac8c68c8c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oxanium_c340b3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/946a1ee6daf04c65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Oxanium_Fallback_c340b3';src: local("Arial");ascent-override: 76.07%;descent-override: 20.22%;line-gap-override: 24.07%;size-adjust: 103.86%
}.__className_c340b3 {font-family: '__Oxanium_c340b3', '__Oxanium_Fallback_c340b3';font-style: normal
}.__variable_c340b3 {--font-oxanium: '__Oxanium_c340b3', '__Oxanium_Fallback_c340b3'
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/917cd3630643261c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/253c2da0d6db7fb7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/aeeb8f17b662c1cb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fefa56e0a0c044be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/76a592a1c919d78c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24f44be2cf98e6f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/917cd3630643261c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/253c2da0d6db7fb7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/aeeb8f17b662c1cb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fefa56e0a0c044be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/76a592a1c919d78c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24f44be2cf98e6f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/917cd3630643261c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/253c2da0d6db7fb7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/aeeb8f17b662c1cb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fefa56e0a0c044be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/76a592a1c919d78c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24f44be2cf98e6f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/917cd3630643261c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/253c2da0d6db7fb7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/aeeb8f17b662c1cb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fefa56e0a0c044be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/76a592a1c919d78c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24f44be2cf98e6f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/7b19b489dc6743ba-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/28793f5c5e3d822d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c9c3823090ec8b55-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b3bf17a9041d9433-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2801417b65625cf5-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/26d4368bf94c0ec4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/7b19b489dc6743ba-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/28793f5c5e3d822d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c9c3823090ec8b55-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b3bf17a9041d9433-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2801417b65625cf5-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/26d4368bf94c0ec4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/7b19b489dc6743ba-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/28793f5c5e3d822d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c9c3823090ec8b55-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b3bf17a9041d9433-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2801417b65625cf5-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/26d4368bf94c0ec4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/7b19b489dc6743ba-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/28793f5c5e3d822d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c9c3823090ec8b55-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b3bf17a9041d9433-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2801417b65625cf5-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_0074d5';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/26d4368bf94c0ec4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_0074d5';src: local("Arial");ascent-override: 101.32%;descent-override: 27.18%;line-gap-override: 0.00%;size-adjust: 101.17%
}.__className_0074d5 {font-family: '__IBM_Plex_Sans_0074d5', '__IBM_Plex_Sans_Fallback_0074d5'
}.__variable_0074d5 {--font-ibmPlexSans: '__IBM_Plex_Sans_0074d5', '__IBM_Plex_Sans_Fallback_0074d5'
}

